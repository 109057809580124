var $ = require('jquery');

var env = 'prod';

if (env === 'prod' || env === 'devProd' || env === 'prodNoSecurity') {
  $('.prod').show();
  $('.stg').hide();
} else if (env === 'stg') {
  $('.prod').hide();
  $('.stg').show();
} else {
  $('#prod').show();
  $('#stg').show();
}

if (env === 'devProd') {
	$('.devProd').hide();
}