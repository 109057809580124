import React from 'react';
import ErrorBoundary from './errorBoundary.jsx';


class BreadCrumbDetail extends React.Component {

  generateBreadCrumbArray(data) {
    let result = [];
    for (let i = 0; i < data.length; i++) {
      let entry = data[i];
      if (entry.href === this.props.target) {
        result.push({text: entry.text, href: entry.href});
      }
      if (entry.hasOwnProperty('nodes') && typeof entry.nodes != 'undefined') {
        let outcome = this.generateBreadCrumbArray(entry.nodes);
        if (outcome != null) {
          result.push({text: entry.text, href: entry.href});
          result = result.concat(outcome);
        }
      }
    }
    if (result.length > 0) {
      return result;
    }
    return null;
  }

  render() {
    let breadcrumbArray = this.generateBreadCrumbArray(this.props.data);
    let entries = [];
    if (breadcrumbArray) {
      breadcrumbArray.forEach((crumb, index) => {
        if (index == (breadcrumbArray.length - 1) || index == 0) {
          entries.push(<li className="active" key={index}>{crumb.text}</li>)
        } else {
          entries.push(<li key={index}><a href={crumb.href}>{crumb.text}</a></li>)
        }
      });
    }
    return (
      <ol className="breadcrumb">
        {entries}
      </ol>
    );
  }
}

class BreadCrumb extends React.Component {


  render() {
    return (
      <ErrorBoundary message=''>
        <BreadCrumbDetail data={this.props.data} target={this.props.target}/>
      </ErrorBoundary>
    )
  }

}

export default BreadCrumb;
