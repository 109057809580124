import React from 'react';
import ReactDOM from 'react-dom';
import ErrorBoundary from './errorBoundary.jsx';

let env = 'prod';

class InfoArea extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let docTeam = [];
    let environment = [];
    docTeam.push(
      <li key="0">Contact Documentation Team: <a href="mailto:ApiSpecTeam@DNB.com" target="_top">ApiSpecTeam@DNB.com</a>
      </li>
    );
    environment.push(
      <li key="1">{env} - {CODEVERSION}</li>
    );
    if (env === 'prod' || env === 'devProd' || env === 'prodNoSecurity') {
      docTeam = [];
      environment = [];
    }
    return (
      <ErrorBoundary message='unable to render footer'>
        <ul className="legal">
          <li className="copyright">&copy; Dun &amp; Bradstreet, Inc. {new Date().getFullYear()}. All Rights Reserved.</li>
          <li><a href="http://support.dnb.com" target="_blank">Need Help? </a></li>
		  <li><a href="https://www.dnb.com/utility-pages/privacy-policy.html" target="_blank">Privacy Policy</a></li>
          {docTeam}
          {environment}
        </ul>
      </ErrorBoundary>
    );
  }

}

ReactDOM.render(<InfoArea/>, document.getElementById('footerInfo'));
