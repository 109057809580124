//JS imports
import hljs from 'highlight.js';
import './js/dnb-product-design-guide.min.js';
import './js/topMenu.jsx';
import './js/footer.jsx';
import './js/indexShow.js';

// CSS imports
import 'primereact/resources/primereact.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'highlight.js/styles/default.css';

//executions
hljs.initHighlightingOnLoad();

